import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Skeleton, Stack } from "@mui/material";
import { useAppInfo } from "@src/appV2/lib/utils/capacitor/useAppInfo";
import { useGetShiftFeedback } from "@src/appV2/ShiftFeedback/useGetShiftFeedback";
import { useGetShiftFeedbackClockOutConfig } from "@src/appV2/Shifts/Shift/useGetShiftFeedbackClockOutConfig";

import { type CancelShiftFeedback } from "../../MyShiftUnavailable/useGetShiftCancelFeedback";
import { getNextShiftState } from "./NextStateContent/nextState";
import { ShiftStateContent } from "./ShiftStateContent";
import { type ShiftStateData, type ShiftStateDependencies } from "./types";

interface Props {
  shiftStateDependencies: ShiftStateDependencies;
  shiftStateData?: ShiftStateData;
  shiftCancelFeedback: CancelShiftFeedback;
}

export function ShiftStateInfo(props: Props) {
  const { shiftStateDependencies, shiftStateData, shiftCancelFeedback } = props;
  const { appInfo } = useAppInfo();
  const { _id: shiftId, facility, agentId } = shiftStateDependencies;
  const { qrCodeFeedbackBeforeClockOutIsEnabled } = useGetShiftFeedbackClockOutConfig(facility.id);
  const { data: shiftFeedback } = useGetShiftFeedback(
    { workerId: agentId ?? "", shiftId },
    {
      enabled: qrCodeFeedbackBeforeClockOutIsEnabled && isDefined(agentId) && isDefined(shiftId),
    }
  );
  const hasShiftFeedback = isDefined(shiftFeedback) && shiftFeedback.length > 0;

  if (!isDefined(shiftStateData)) {
    return (
      <Stack spacing={0.5}>
        <Skeleton variant="text" width="40%" height={20} />
        <Skeleton variant="text" width="80%" height={18} />
        <Skeleton variant="text" width="80%" height={18} />
      </Stack>
    );
  }

  const nextState = shiftStateData?.state
    ? getNextShiftState({ ...shiftStateData, appInfo })
    : undefined;

  if (!isDefined(nextState)) {
    // In this case, the shift state doesn't have a "next state" that's currently presentable by our UI.
    return null;
  }

  return (
    <Stack spacing={{ xs: 1, sm: 2 }}>
      {/* FIXME Remove local color override - https://linear.app/clipboardhealth/issue/FEF-101 */}
      <Title variant="h4" component="h1" color="black !important">
        <b>What&apos;s Next?</b>
      </Title>
      <ShiftStateContent
        nextState={nextState}
        shiftStateMetadata={shiftStateData?.metadata}
        shiftStateDependencies={shiftStateDependencies}
      />
      {isDefined(shiftCancelFeedback.message) && !hasShiftFeedback ? (
        <Text>{shiftCancelFeedback.message}</Text>
      ) : null}
    </Stack>
  );
}
